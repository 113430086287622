import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { numericFormatDate } from "utils/commonUtils";
import Link from "components/templatesComponents/Link";
import CardHighlightedContext from "components/CardHighlightedContext";
import LanguageContext from "components/LanguageContext";
import PageService from "services/PageService";
import SiteService from "services/SiteService";
import useAxiosCache from "hooks/axiosCache";
import t from "utils/locales/translation.json";

const CardHighlighted = ({ page: pageVersion }) => {
  const { image, title, updated, fullPath, page, pageId } = pageVersion || {};
  const { lang, originalPageId, siteId } = page || {};
  const { props } = image || {};
  const { file, alt } = props || {};
  const { url } = file || {};

  const { language } = useContext(LanguageContext);

  const { isHorizontal } = useContext(CardHighlightedContext);

  const [{ data: allSites = [] }] = useAxiosCache(SiteService.getConfig("getAllSites"));

  const [{ data: frenchPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      pageId: lang === "FR" ? pageId : originalPageId,
      filters: [`page.lang||eq||FR`],
    })
  );

  const findSiteById = useMemo(() => allSites.find((site) => site.id === siteId), [allSites, siteId]);

  const { host } = findSiteById || {};

  const { updated: frenchPageUpdated } = frenchPnuPages[0] || {};

  const date = lang === "FR" ? updated : frenchPageUpdated || updated;

  if (pageVersion === undefined) {
    return null;
  }

  const isBoFullPath =
    fullPath && fullPath.includes("site=") && fullPath.includes("page=") && fullPath.includes("version=");

  return (
    <div className={`fr-card fr-enlarge-link ${isHorizontal && "fr-card--horizontal-tier"}`}>
      <div className="fr-card__body">
        <div className="fr-card__content">
          <h4 className="fr-card__title">
            <Link button url={isBoFullPath ? fullPath : `${host}${fullPath}`} style={{ wordBreak: "break-word" }}>
              {title}
            </Link>
          </h4>
          {updated && (
            <div className="fr-card__start">
              <p className="fr-card__detail fr-icon-calendar-event-fill" role="presentation">
                {t[language].common.news.updated_label} {numericFormatDate({ date, lang })}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="fr-card__header">
        <div className="fr-card__img">
          <img className="fr-responsive-img" src={url || "/default-image.png"} alt={alt} />
        </div>
        <ul className="fr-badges-group">
          <li>
            <p className="fr-badge fr-badge--info" role="presentation">
              {t[language].common.news.highlighted}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

CardHighlighted.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default CardHighlighted;

import axios from "axios";

class ContactService {
  static getWebmail = (contentId, pageId, captchaToken) => {
    return axios.post("/@webmail", { contentId, pageId, captchaToken }).then((response) => response.data);
  };

  static postMail = (sendForm) => {
    return axios
      .post("/mail", sendForm)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return {
          status: err.response && err.response.status,
          message: err.response && err.response.data && err.response.data.error,
        };
      });
  };
}

export default ContactService;

import Chip from "@material-ui/core/Chip";
import React from "react";
import Icon from "components/templatesComponents/Icon";
import { getSiteName } from "utils/commonUtils";

export const types = [
  { value: "info", label: "Information" },
  { value: "warning", label: "Avertissement" },
  { value: "alert", label: "Alerte" },
];

export const dateNowFormatted = new Date()
  .toISOString()
  .split(":")[0]
  .concat(":", new Date().toISOString().split(":")[1]);

export const localeDateNow = Date.now() + 1000 * 3600 * 2;

const formatDate = (date) => {
  const newDate = new Date(date).setHours(new Date(date).getHours() - 2);
  return new Date(newDate).toLocaleDateString("FR-fr", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getDisplayPeriod = (banner) => {
  const { forceDisplay, displayStartDate, displayEndDate } = banner;
  if (forceDisplay) {
    return <p>Permanent</p>;
  }
  if (displayStartDate && displayEndDate) {
    if (localeDateNow > Date.parse(displayEndDate)) {
      return (
        <i>
          Expiré
          <Icon
            iconDSFR="information-line"
            title={`Du ${formatDate(banner.displayStartDate)} au ${formatDate(banner.displayEndDate)}`}
            style={{ marginLeft: "8px" }}
          />
        </i>
      );
    }
    return (
      <>
        <p>Du {formatDate(banner.displayStartDate)}</p>
        <p>au {formatDate(banner.displayEndDate)}</p>
      </>
    );
  }
  return <i>Aucune période définie</i>;
};

export const displaySortedSites = (sites = []) =>
  sites
    .sort((a, b) => getSiteName(a.name)?.localeCompare(getSiteName(b.name)))
    .map((site) => (
      <Chip key={site.id} label={getSiteName(site.name)} color="primary" variant="outlined" style={{ margin: "2px" }} />
    ));

import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import CookiesConsentService from "services/CookiesConsentService";
import EulerianService from "services/EulerianService";
import CookiesModalContext from "components/CookiesModalContext";
import LanguageContext from "components/LanguageContext";
import CookiesModal from "components/CookiesModal";
import Link from "components/templatesComponents/Link";
import t from "utils/locales/translation.json";

const CookiesBanner = (props) => {
  const { bo } = props;
  const [cookieConsent, setCookieConsent] = useState(true);

  const { handleCookiesModal } = useContext(CookiesModalContext);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    setCookieConsent(CookiesConsentService.getConsent());
  }, []);

  const handleValidateClick = () => {
    setCookieConsent(true);
    CookiesConsentService.acceptConsent();
    EulerianService.acceptAll();
  };

  const handleRefuseClick = () => {
    setCookieConsent(false);
    CookiesConsentService.refuseConsent();
    EulerianService.denyAll();
  };

  const host =
    (typeof window !== "undefined" &&
      `${t[language].components.cookies_banner_host_prefix} ${window.location?.host}`) ||
    "";

  return (
    <>
      {!bo && cookieConsent === null && (
        <div className="fr-consent-banner">
          <h2 className="fr-h6">
            {t[language].components.cookies_banner_title} {host}{" "}
          </h2>
          <div className="fr-consent-banner__content">
            <p className="fr-text--sm">
              {t[language].components.cookies_banner_description}
              <Link linkComponent external size="sm" url="/politique-de-confidentialite" style={{ color: "inherit" }}>
                {t[language].components.cookies_banner_link}
              </Link>
              .
            </p>
          </div>
          <ul className="fr-consent-banner__buttons fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-sm">
            <li>
              <button
                type="button"
                className="fr-btn"
                title={t[language].components.accept_button_title}
                onClick={handleValidateClick}
              >
                {t[language].components.accept_button}
              </button>
            </li>
            <li>
              <button
                type="button"
                className="fr-btn fr-btn--secondary"
                data-fr-opened="false"
                aria-controls="fr-consent-modal"
                title={t[language].components.personalize_button_title}
                style={{ boxShadow: "inset 0 0 0 1px var(--border-action-high-blue-france)" }}
                onClick={(e) => {
                  handleCookiesModal();
                  e.stopPropagation();
                }}
              >
                {t[language].components.personalize_button_label}
              </button>
            </li>
          </ul>
        </div>
      )}
      <CookiesModal
        cookieConsent={cookieConsent}
        handleCookiesModal={handleCookiesModal}
        handleValidateClick={handleValidateClick}
        handleRefuseClick={handleRefuseClick}
      />
    </>
  );
};

CookiesBanner.propTypes = {
  bo: PropTypes.bool,
};

CookiesBanner.defaultProps = {
  bo: false,
};

export default CookiesBanner;
